<template>
  <div>
    <div class="row">
      <div class="col">
        <ul class="list-group mb-3" style="border-radius: 0.25rem; box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);" :class="darkMode">
          <!-- Item List -->
          <div v-if="itemData != null">
            <template v-if="itemData.length > 0">
              <template v-for="item in itemData">
                <li :key="item.id" class="list-group-item inherit-color p-4">
                  <div class="row">
                    <div class="col-2 pr-0 d-flex justify-content-center align-items-center">
                      <img :src="getIcon(item.category)" style="max-height: 55px;" class="img-fluid" />
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <h6 class="my-0 oneline">{{item.count}}x {{item.name}}</h6>
                          <small class="text-muted">{{item.category}}</small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <small class="text-muted">Total: {{convertToDinero(item.price)}}</small>
                        </div>
                        <div class="col d-flex justify-content-end">
                          <button type="button" class="btn btn-danger btn-sm" @click="deleteItem(item.id)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </template>
            <template v-else>
              <li class="list-group-item inherit-color p-4">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <h6 class="my-0 oneline">No items in order</h6>
                  </div>
                </div>
              </li>
            </template>
          </div>
          <div v-else>
            <li class="list-group-item inherit-color d-flex justify-content-center align-items-center lh-condensed">
              <div class="spinner-border text-primary m-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </li>
          </div>
          <!-- Item List -->
          <li class="list-group-item inherit-color d-flex justify-content-between">
            <span>Total (GBP)</span>
            <strong v-if="itemData != null">{{convertToDinero(totalAmount)}}</strong>
            <strong v-else>£0.00</strong>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-end flex-row">
        <router-link tag="button" to="/order/menu" class="btn btn-danger mr-2">Cancel</router-link>
        <router-link tag="button" to="/order/pay/confirm" class="btn" v-bind:class="darkModeBtn" :disabled="itemData.length == 0 || totalAmount < 100">Checkout</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { darkModeStyle } from '@assets/js/darkMode';
import icons from '@assets/js/icons';
import Dinero from 'dinero.js'

export default {
  name: "OrderPayDetails",
  data() {
    return {
      itemData: null,
      totalAmount: 0,
      darkMode: darkModeStyle("bg-dark text-light", "bg-light text-dark"),
      darkModeBade: darkModeStyle("badge-dark", "badge-light"),
      darkModeBtn: darkModeStyle("btn-dark", "btn-light"),
      snackbar: null,
    }
  },
  methods: {
    deleteItem(id) {
      this.$shoppingCart.removeItemFromCart(id);
    },
    getIcon: function(name) {
      return icons[name];
    },
    convertToDinero(value) {
      return Dinero({ amount: value, currency: 'GBP' }).toFormat()
    },
    totalFromItems(items) {
      var totalCart = 0
      for (var item in items) {
        totalCart += items[item].price * items[item].count
      }
      return Number(totalCart.toFixed(2))
    },
    totalItemCount(value) {
      if (typeof value != 'object') {
        return 0;
      }

      return this.itemData.length;
    },
    closeifnotnull(item) {
      if (item != null) {
        item.close();
      }
    },
  },
  watch: {
    '$store.state.cart.shoppingCart': {
      handler: function(itemData) {
        const itemTotal = this.totalFromItems(itemData);

        this.itemData = itemData;
        this.totalAmount = itemTotal;

        if (this.totalItemCount(itemData) == 0) {
          this.closeifnotnull(this.snackbar);
          this.snackbar = this.$buefy.snackbar.open({
            type: 'is-warning',
            message: 'No items in your cart, please try adding something to your cart',
            indefinite: true
          });
        }

        if (itemTotal < 100 && this.totalItemCount(itemData) > 0) {
          this.closeifnotnull(this.snackbar);
          this.snackbar = this.$buefy.snackbar.open({
            type: 'is-warning',
            message: 'Your total is less than £1, please add more items to your cart',
            indefinite: true
          });
        }
      },
      deep: true
    }
  },
  created() {
    const itemData = this.$store.getters.getCart;
    const itemTotal = this.totalFromItems(itemData)

    this.itemData = itemData;
    this.totalAmount = itemTotal;


    if (this.totalItemCount(itemData) == 0) {
      this.closeifnotnull(this.snackbar);
      this.snackbar = this.$buefy.snackbar.open({
        type: 'is-warning',
        message: 'No items in your cart, please try adding something to your cart',
        indefinite: true
      });
    }

    if (itemTotal < 100 && this.totalItemCount(itemData) > 0) {
      this.closeifnotnull(this.snackbar);
      this.snackbar = this.$buefy.snackbar.open({
        type: 'is-warning',
        message: 'Your total is less than £1, please add more items to your cart',
        indefinite: true
      });
    }

    console.log(this.$store.state.cart.shoppingCart)


  },
  destroyed() {
    this.closeifnotnull(this.snackbar);
  }
}
</script>